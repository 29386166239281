import * as React from "react"
import { motion, useViewportScroll, useTransform, m } from "framer-motion";
import Layout from "../components/layout"
import ContactForm from "../components/ContactForm"
import Seo from "../components/seo";

const ContactPage = () => (
  <Layout>
    <Seo title="Work with me - David Krasniy" />
 
    <motion.div className='flex flex-col justify-center items-start max-w-4xl border-gray-200 dark:border-gray-700 mx-auto pb-16 pt-12'
      initial={{ opacity: 0, y: 2 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: .3 }, }}

      viewport={{ once: true }}>

      <ContactForm />
    </motion.div>


  </Layout>
)

export default ContactPage
